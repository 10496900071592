import Phaser from 'phaser';
import { createRoot } from 'react-dom/client';
import { preloadPlayer, createPlayer, updatePlayer, setupPlayerControls } from './player';
import { furniturecollision } from '../asset/tilemap/collisions';
import { preloadbook, createbookanim } from './sprite';
import BookPopup from './bookpopup';


export default class NewScene extends Phaser.Scene {
  constructor() {
    super({ key: 'NewScene' });
    this.isPopupOpen = false; // Flag to indicate if popup is open
  }

  init(data) {
    this.playerPosition = data.playerPosition || { x: 100, y: 100 }; // Default position if none is passed
    console.log("Initializing scene with player position: ", this.playerPosition);
  }

  preload() {
    console.log("Preloading new scene assets...");
    this.load.image('house', '/assets/tiles/inside1.png');
    this.load.image('insideforeground', '/assets/tiles/insideforeground.png');
    preloadPlayer(this);
    preloadbook(this);
    // Load any new assets for the new scene here
  }

  create(data) {
    console.log("Creating new scene...");

    const tileSize = 40;
    const offsetX = 300;
    const offsetY = -300;
    const scaledTileWidth = tileSize * 0.5;
    const scaledTileHeight = tileSize * 0.3;
    const furniturelayer = [];

    if (this.textures.exists('house')) {
      this.add.image(offsetX, offsetY, 'house').setOrigin(0, 0);
      console.log("Added house image");
    } else {
      console.error('House image not found in cache');
    }

    createbookanim(this, 690, 235);
    const playerPosition = data?.playerPosition || this.playerPosition; // Update player position if data is passed
    console.log("Player position: ", playerPosition);
    this.player = createPlayer(this, playerPosition);
    this.cursors = this.input.keyboard.createCursorKeys();
    
    this.cameras.main.startFollow(this.player, true, 0.05, 0.05);
    
    // Collision setup for newScene
    this.collisionTiles = this.physics.add.staticGroup();
    this.doorTiles = this.physics.add.staticGroup();
    this.bookcollision = this.physics.add.staticGroup();
    this.graphics = this.add.graphics({ fillStyle: { color: 0xff0000, alpha: 0.3 } });
    
    for (let i = 0; i < furniturecollision.length; i += 20) {
      furniturelayer.push(furniturecollision.slice(i, 20 + i));
    }
    
    console.log("Furniture layer:", furniturelayer);
    
    furniturelayer.forEach((row, rowIndex) => {
      row.forEach((tile, colIndex) => {
        const tileX = colIndex * tileSize + offsetX + 25;
        const tileY = rowIndex * tileSize + offsetY + 15;
        if (tile === 42) {
          const collisionTile = this.collisionTiles.create(tileX, tileY, null).setOrigin(0, 0).setDisplaySize(scaledTileWidth, scaledTileHeight).setVisible(false);
          collisionTile.body.setSize(scaledTileWidth, scaledTileHeight).setOffset(0, 0);
          // this.graphics.fillRect(tileX, tileY, scaledTileWidth, scaledTileHeight);
          console.log("Added collision tile at: ", tileX, tileY);
        } else if (tile === 1849) {
          const doorTile = this.doorTiles.create(tileX, tileY, null).setOrigin(0, 0).setDisplaySize(scaledTileWidth, scaledTileHeight).setVisible(false);
          doorTile.body.setSize(scaledTileWidth, scaledTileHeight).setOffset(0, 20);
          // this.graphics.fillRect(tileX, tileY, scaledTileWidth, scaledTileHeight);
          console.log("Added door tile at: ", tileX, tileY);
        } else if (tile === 80) {
          const Bookcollision = this.bookcollision.create(tileX, tileY, null).setOrigin(0, 0).setDisplaySize(scaledTileWidth, scaledTileHeight).setVisible(false);
          Bookcollision.body.setSize(scaledTileWidth, scaledTileHeight).setOffset(0, 0);
          // this.graphics.fillRect(tileX, tileY, scaledTileWidth, scaledTileHeight);
          console.log("Added book tile at: ", tileX, tileY);
        }
      });
    });
    
    this.physics.add.collider(this.player, this.collisionTiles, () => {
      console.log('Colliding with collision tile');
    });
    
    this.physics.add.overlap(this.player, this.doorTiles, (player, doorTile) => {
      console.log('Colliding with door tile');
      this.cleanup(); // Clean up resources when transitioning
      this.scene.stop('NewScene'); // Pause NewScene
      this.scene.start('GameMap', { playerPosition: { x: doorTile.x, y: doorTile.y } }); // Resume GameMap with door tile position
    });
    let isOverlapping = false;

this.physics.add.overlap(this.player, this.bookcollision, () => {
  console.log('overlap with book');
  
  // Set the flag to true when overlap occurs
  isOverlapping = true;
  this.input.keyboard.on('keydown-ENTER', () => {
    if (isOverlapping) {
      this.showBlogPopup();
      isOverlapping = false;
    }
  });
});

// Check the overlap flag before triggering the 'keydown-ENTER' event

    
    this.insideforeground = this.add.image(offsetX, offsetY, 'insideforeground').setOrigin(0, 0);
    console.log("Added inside foreground");
    
    // Additional setup for the new scene
    setupPlayerControls(this, this.player);
  }
  
  update() {
    if (!this.isPopupOpen) { // Check if popup is open
      updatePlayer(this, this.player, this.cursors, this.playerPosition);
    }
    // Update logic for the new scene
  }

  cleanup() {
    // Clean up resources to free memory
    this.collisionTiles.clear(true, true);
    this.doorTiles.clear(true, true);
    this.graphics.destroy();
    this.insideforeground.destroy();

    console.log("Resources cleaned up");
  }

  showBlogPopup() {
    this.isPopupOpen = true; // Set flag to true when popup is open
    if (!this.popupContainer) {
      this.popupContainer = document.createElement('div');
      this.popupContainer.id = 'popup-container';
      document.body.appendChild(this.popupContainer);
    }

    if (!this.popupRoot) {
      this.popupRoot = createRoot(this.popupContainer);
    }

    this.popupRoot.render(
      <BookPopup
        show={true}
        onClose={() => {
          this.isPopupOpen = false; // Set flag to false when popup is closed
          this.popupRoot.unmount();
          document.body.removeChild(this.popupContainer);
          this.popupContainer = null;
          this.popupRoot = null;
        }}
      />
    );
  }
}
