import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import '../Components/popup.css';

export const useBlogData = (show) => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [subcategories, setSubcategories] = useState([]);
    const [blogs, setBlogs] = useState([]);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [showSubcategories, setShowSubcategories] = useState(false);
    const [showBlogContent, setShowBlogContent] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedBlogIndex, setSelectedBlogIndex] = useState(0);

    useEffect(() => {
        if (show) {
            axios.get(`https://heman.24hragency.com/Api/database.php?categories=true`)
                .then(response => {
                    setCategories(response.data);
                })
                .catch(error => {
                    console.error('Error fetching categories:', error);
                });
        }
    }, [show]);

    useEffect(() => {
        if (selectedCategory) {
            const selectedCat = categories.find(cat => cat.id === selectedCategory);
            if (selectedCat) {
                setSubcategories(selectedCat.subcategories || []);
                setBlogs(selectedCat.blogs || []);
            }
        }
    }, [selectedCategory, categories]);

    return {
        categories,
        subcategories,
        blogs,
        selectedBlog,
        showSubcategories,
        showBlogContent,
        selectedIndex,
        selectedBlogIndex,
        setSelectedIndex,
        setSelectedCategory,
        setShowSubcategories,
        setShowBlogContent,
        setBlogs,
        setSelectedBlog,
        setSelectedBlogIndex,
    };
};

export const BlogSection = ({
    categories,
    subcategories,
    blogs,
    selectedBlog,
    showSubcategories,
    showBlogContent,
    selectedIndex,
    selectedBlogIndex,
    handleCategoryClick,
    setBlogs,
    setSelectedIndex,
    setShowBlogContent,
    setSelectedBlog,
    setSelectedBlogIndex,
}) => {
    const formatContent = (content) => {
        return content.split('\n').map((str, index) => (
            <React.Fragment key={index}>
                {str}
                <br />
            </React.Fragment>
        ));
    };

    const blogTitlesRef = useRef(null);
    const blogContentRef = useRef(null);

    useEffect(() => {
        if (showBlogContent) {
            blogContentRef.current.scrollTop = 0;
        }
    }, [selectedBlog, showBlogContent]);

    const handleBlogClick = (blog, index) => {
        setSelectedBlog(blog);
        setSelectedBlogIndex(index);
        setShowBlogContent(true);
        const selectedBlogTitle = document.querySelectorAll('.blog-title')[index];
        if (selectedBlogTitle) {
            selectedBlogTitle.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <>
            {!showSubcategories ? (
                <div className="column1">
                    {categories.map((category, index) => (
                        <div
                            key={category.id}
                            onClick={() => handleCategoryClick(category.id)}
                            className={`clickable ${index === selectedIndex ? 'selected' : ''}`}
                        >
                            {category.name}
                        </div>
                    ))}
                </div>
            ) : !showBlogContent ? (
                <>
                    <div className="column2">
                        <p>I wasn't able to make the control go to the order side so please use mouse to select it.</p>
                        {subcategories.map((subcategory, index) => (
                            <div
                                key={subcategory.id}
                                onClick={() => {
                                    setBlogs(subcategory.blogs || []);
                                    setSelectedIndex(index);
                                }}
                                className={`clickable ${index === selectedIndex ? 'selected' : ''}`}
                            >
                                {subcategory.name}
                            </div>
                        ))}
                    </div>
                    <div className="blogs" ref={blogTitlesRef}>
                        {blogs.map((blog, index) => (
                            <div
                                key={blog.id}
                                onClick={() => handleBlogClick(blog, index)}
                                className={`blog-title clickable ${index === selectedBlogIndex ? 'selected' : ''}`}
                            >
                                {blog.title}
                            </div>
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div className="blogs" ref={blogTitlesRef}>
                        {blogs.map((blog, index) => (
                            <div
                                key={blog.id}
                                onClick={() => handleBlogClick(blog, index)}
                                className={`blog-title clickable ${index === selectedBlogIndex ? 'selected' : ''}`}
                            >
                                {blog.title}
                            </div>
                        ))}
                    </div>
                    <div className="blog-content" ref={blogContentRef}>
                        <h2>{selectedBlog.title}</h2>
                        <p>{formatContent(selectedBlog.content)}</p>
                    </div>
                </>
            )}
        </>
    );
};