import React, { useState, useEffect } from 'react';
import './myworks.css';

const importAll = (requireContext) => requireContext.keys().map(requireContext);

const MyWorks = () => {
  const [logoImages, setLogoImages] = useState([]);
  const [businesscardImages, setBusinesscardImages] = useState([]);
  const [currentLogoIndex, setCurrentLogoIndex] = useState(0);
  const [currentBusinesscardIndex, setCurrentBusinesscardIndex] = useState(0);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const logoImages = importAll(require.context('../../public/assets/logo', false, /\.(png|jpe?g|svg)$/));
    setLogoImages(logoImages);
    const businesscardImages = importAll(require.context('../../public/assets/Businesscard', false, /\.(png|jpe?g|svg)$/));
    setBusinesscardImages(businesscardImages);
  }, []);

  const openImage = (img) => {
    setSelectedImage(img);
  };

  const closeImage = () => {
    setSelectedImage(null);
  };

  const showPrevLogoImage = () => {
    if (currentLogoIndex > 0) {
      setCurrentLogoIndex(currentLogoIndex - 1);
    }
  };

  const showNextLogoImage = () => {
    if (currentLogoIndex < logoImages.length - 1) {
      setCurrentLogoIndex(currentLogoIndex + 1);
    }
  };

  const showPrevBusinesscardImage = () => {
    if (currentBusinesscardIndex > 0) {
      setCurrentBusinesscardIndex(currentBusinesscardIndex - 1);
    }
  };

  const showNextBusinesscardImage = () => {
    if (currentBusinesscardIndex < businesscardImages.length - 1) {
      setCurrentBusinesscardIndex(currentBusinesscardIndex + 1);
    }
  };

  const websiteLinks = [
    { text: '24hragency', url: 'https://24hragency.com' },
    { text: 'GharAghi', url: 'https://gharaghi.com' },
    { text: 'TURMERIC:The Golden Touch', url: 'https://turmericthegoldentouch.com' },
    { text: 'Alison Trimper', url: 'https://www.alisontrimper.com.au' },
    { text: 'Avidity care', url: 'https://aviditycare.com.au' },
    { text: 'Right Price E Bike', url: 'https://rightpriceebike.com' },
    { text: 'TSV Social', url: 'https://tsvsocial.com.au' },
    { text: 'LushLux', url: 'https://lushlux.com.au' },
    { text: 'Southern Cross Salsa', url: 'https://southerncrosssalsa.com' },
    { text: 'Posenthanphotography', url: 'http://ponsenthanphotography.com' },
    { text: 'After Care Services', url: 'https://aftercareservices.com.au' },
    { text: 'SCS Venue', url: 'https://venue.southerncrosssalsa.com' },
    { text: 'Roles & Simran', url: 'https://roleshandsimran.com' },
    { text: 'Liberty Groom', url: 'https://libertygroom.com' },
    { text: 'M kami', url: 'https://mkamijapaneseheadspa.com.au' },
    { text: 'Good Choice Flooring', url: 'https://goodchoiceflooring.com' },
    { text: 'Ultimate Golf Society', url: 'https://ultimategolfsociety.com' },
    { text: 'Aarya Bajra', url: 'https://aaryabajramultitrading.com' },
    { text: 'Primos Enterprise', url: 'https://primosenterprise.com' },
    { text: 'SOCC', url: 'https://streetsopening.com.au' },
    { text: 'Packweigh Electrical', url: 'https://packweighelectrical.com.au' },
    // Add more links as needed
  ];

  const renderLinks = (links) => {
    return (
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="section-content">
      <div className='firstpage'>
        <h2>Websites</h2>
        <div className='websites'>
          <h2>WordPress:</h2>
          {renderLinks(websiteLinks)}
        </div>
      </div>
      <div className='secondpage'>
        <h2>Designs</h2>
        <div className='designs'>
          <h2>Logo:</h2>
          <div className="logo-container">
            <button className="nav-button left-button" onClick={showPrevLogoImage}>&#10094;</button>
            <div className="logo-wrapper" style={{ transform: `translateX(-${currentLogoIndex * 100}%)` }}>
              {logoImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`logo-${index}`}
                  onClick={() => openImage(img)}
                  className="logo-thumb"
                />
              ))}
            </div>
            <button className="nav-button right-button" onClick={showNextLogoImage}>&#10095;</button>
          </div>
          <h2>Business Cards:</h2>
          <div className="businesscard-container">
            <button className="nav-button left-button" onClick={showPrevBusinesscardImage}>&#10094;</button>
            <div className="businesscard-wrapper" style={{ transform: `translateX(-${currentBusinesscardIndex * 100}%)` }}>
              {businesscardImages.map((img, index) => (
                <img
                  key={index}
                  src={img}
                  alt={`businesscard-${index}`}
                  onClick={() => openImage(img)}
                  className="businesscard-thumb"
                />
              ))}
            </div>
            <button className="nav-button right-button" onClick={showNextBusinesscardImage}>&#10095;</button>
          </div>
          {selectedImage && (
            <div className="modal" onClick={closeImage}>
              <span className="close">&times;</span>
              <img className="modal-content" src={selectedImage} alt="money" />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyWorks;
