// createDialogBox.js
export const createDialogBox = (scene, player, messages, onComplete) => {
    let dialogBox;
    let dialogText;
    let closeButton;
    let nextButton;
    let messageIndex = 0;
    let isAnimating = false;
    const padding = 20; 

    const createTextObject = (message) => {
        const tempText = scene.add.text(0, 0, message, {
            fontSize: '16px',
            
            wordWrap: { width: Math.min(scene.scale.width * 0.4 - padding * 2, 400 - padding * 2) } 
        });
        return tempText;
    };

    const adjustDialogBoxSize = (message) => {
        let tempText = createTextObject(message);
        const textWidth = !tempText.width;
        const textHeight = !tempText.height;
        tempText.destroy();

        const dialogWidth = Math.max(350, textWidth + 20); 
        const dialogHeight = Math.max(170, textHeight/2 ); 
        if (dialogBox) dialogBox.setDisplaySize(dialogWidth, dialogHeight);
        if (dialogText) dialogText.setWordWrapWidth(dialogWidth - 80);
        return { dialogWidth, dialogHeight };
    };

    const repositionDialogElements = (dialogWidth, dialogHeight) => {
        if (dialogBox) {
            dialogBox.x = player.x;
            dialogBox.y = player.y - player.height / 2 - dialogHeight / 2 - 20;
        }
        if (dialogText) {
            dialogText.x = dialogBox.x - dialogWidth / 2 + padding*2; 
            dialogText.y = dialogBox.y - dialogHeight / 2 + padding*1.7; 
        }
        if (closeButton) {
            closeButton.x = dialogBox.x + dialogWidth / 2 - 40;
            closeButton.y = dialogBox.y - dialogHeight / 2 + 30;
        }
        if (nextButton) {
            nextButton.x = dialogBox.x + dialogWidth / 2 - 90;
            nextButton.y = dialogBox.y + dialogHeight / 2 - 30;
        }
    };

    const createDialogElements = () => {
        dialogBox = scene.add.image(player.x, player.y - player.height / 2 - 60, 'dialogBox','').setOrigin(0.5);
        dialogText = scene.add.text(player.x, player.y - player.height / 2 - 60, '', {
            fontSize: '16px',
            color: '#fff',
            align: 'left', 
            wordWrap: { width: Math.min(scene.scale.width * 0.4 - padding * 2, 400 - padding * 2) } 
        }).setOrigin(0);

        closeButton = scene.add.image(player.x, player.y, 'closeButton').setDisplaySize(15, 15).setOrigin(0.5);
        closeButton.setInteractive();
        closeButton.on('pointerdown', () => {
            if (!isAnimating) {
                if (dialogBox) dialogBox.destroy();
                if (dialogText) dialogText.destroy();
                if (closeButton) closeButton.destroy();
                if (nextButton) nextButton.destroy();
                if (onComplete) {
                    onComplete();
                }
            }
        });
        

        nextButton = scene.add.image(player.x, player.y, 'nextButton').setDisplaySize(80, 20).setOrigin(0.25);
        nextButton.setInteractive();
        nextButton.on('pointerdown', () => {
            if (!isAnimating) {
                showNextMessage();
            }
        });

        scene.input.keyboard.on('keydown-ENTER', () => {
            if (!isAnimating) {
                showNextMessage();
            }
        });
        scene.input.keyboard.on('keydown-ESC', () => {
            if (!isAnimating) {
                if (dialogBox) dialogBox.destroy();
                if (dialogText) dialogText.destroy();
                if (closeButton) closeButton.destroy();
                if (nextButton) nextButton.destroy();
                if (onComplete) {
                    onComplete();
                }
            }
        });
    };

    const showNextMessage = () => {
        if (messageIndex < messages.length) {
            const message = messages[messageIndex];
            let currentText = '';
            let charIndex = 0;

            isAnimating = true;
            closeButton.disableInteractive();
            nextButton.disableInteractive();

            const typewriterEffect = () => {
                if (charIndex < message.length) {
                    currentText += message.charAt(charIndex);
                    dialogText.setText(currentText);
                    let { dialogWidth, dialogHeight } = adjustDialogBoxSize(currentText);
                    repositionDialogElements(dialogWidth, dialogHeight);
                    charIndex++;
                    scene.time.delayedCall(50, typewriterEffect, [], this); // Adjust speed here
                } else {
                    isAnimating = false;
                    closeButton.setInteractive();
                    nextButton.setInteractive();
                    messageIndex++;
                    if (messageIndex < messages.length) {
                        nextButton.setVisible(true);
                    } else {
                        nextButton.setVisible(false);
                    }
                }
            };

            typewriterEffect();
        }
    };

    // const resizeHandler = () => {
    //     let { dialogWidth, dialogHeight } = adjustDialogBoxSize(messages[messageIndex]);
    //     repositionDialogElements(dialogWidth, dialogHeight);
    // };

    // scene.scale.on('resize', resizeHandler);

    createDialogElements();

    let { dialogWidth, dialogHeight } = adjustDialogBoxSize(messages[messageIndex]);
    repositionDialogElements(dialogWidth, dialogHeight);

    showNextMessage(); 

    // scene.events.on('shutdown', () => {
    //     scene.scale.off('resize', resizeHandler);
    // });
};
