export const preloadbook=(scene)=>{
    scene.load.spritesheet('book', '/assets/tiles/book.png', {
        frameWidth: 16,
        frameHeight: 16,
      });
};

export const createbookanim = (scene,bookx,booky) => {

    scene.anims.create({
        key:'anim',
        frames: scene.anims.generateFrameNumbers('book', { start: 0, end: 3 }),
        frameRate: 5,
        repeat: -1,
        yoyo:true,

      });
      scene.add.text(
        bookx -100,
        -booky -200, 
        'Press ENTER to open the book', 
        {
          fontSize: '14px',
          stroke:'#fff',
          strokeThickness:'1',
          fill: '#fff'
        }
      );
const Bookanim=scene.physics.add.sprite(bookx,-booky,'book',0).setOrigin(0.5,0.5);
Bookanim.play('anim');
Bookanim.setScale(2);
 

}

