

export const preloadPlayer = (scene) => {
    scene.load.spritesheet('player', '/assets/Player/player1.png', {
      frameWidth: 16,
      frameHeight: 22.8,
    });
  };
  
  export const createPlayer = (scene, initialPosition) => {
    scene.anims.create({
      key: 'idle',
      frames: [{ key: 'player', frame: 3 }],
    });
  
    scene.anims.create({
      key: 'left',
      frames: scene.anims.generateFrameNumbers('player', { start: 9, end: 10 }),
      frameRate: 10,
      repeat: -1,
    });
  
    scene.anims.create({
      key: 'right',
      frames: scene.anims.generateFrameNumbers('player', { start: 1, end: 2 }),
      frameRate: 10,
      repeat: -1,
    });
  
    scene.anims.create({
      key: 'up',
      frames: scene.anims.generateFrameNumbers('player', { start: 6, end: 7 }),
      frameRate: 10,
      repeat: -1,
    });
  
    scene.anims.create({
      key: 'down',
      frames: scene.anims.generateFrameNumbers('player', { start: 4, end: 5 }),
      frameRate: 10,
      repeat: -1,
    });
  
    const player = scene.physics.add.sprite(initialPosition.x, initialPosition.y, 'player', 0).setOrigin(0.5, 0.5);
    player.play('idle');
    player.setScale(2.5);
  
    return player;
  };
  
  export const setupPlayerControls = (scene, player) => {
    document.getElementById('left-button').addEventListener('touchstart', () => { player.setData('isMovingLeft', true); });
    document.getElementById('right-button').addEventListener('touchstart', () => { player.setData('isMovingRight', true); });
    document.getElementById('up-button').addEventListener('touchstart', () => { player.setData('isMovingUp', true); });
    document.getElementById('down-button').addEventListener('touchstart', () => { player.setData('isMovingDown', true); });
    document.getElementById('button-a').addEventListener('touchstart', () => { scene.input.keyboard.emit('keydown-ENTER'); });
    document.getElementById('button-b').addEventListener('touchstart', () => { scene.input.keyboard.emit('keydown-ESC'); });
  
    document.getElementById('left-button').addEventListener('touchend', () => { player.setData('isMovingLeft', false); });
    document.getElementById('right-button').addEventListener('touchend', () => { player.setData('isMovingRight', false); });
    document.getElementById('up-button').addEventListener('touchend', () => { player.setData('isMovingUp', false); });
    document.getElementById('down-button').addEventListener('touchend', () => { player.setData('isMovingDown', false); });
  };
  
  export const updatePlayer = (scene, player, cursors, playerPosition) => {
    player.setVelocity(0);
    const Keys = scene.input.keyboard.addKeys('W,A,S,D,ENTER,ESC');
  
    if (cursors.left.isDown || Keys.A.isDown || player.getData('isMovingLeft')) {
      player.setVelocityX(-160);
      if (player.anims.currentAnim.key !== 'left') {
        player.play('left', true);
      }
    } else if (cursors.right.isDown || Keys.D.isDown || player.getData('isMovingRight')) {
      player.setVelocityX(160);
      if (player.anims.currentAnim.key !== 'right') {
        player.play('right', true);
      }
    } else if (cursors.up.isDown || Keys.W.isDown || player.getData('isMovingUp')) {
      player.setVelocityY(-160);
      if (player.anims.currentAnim.key !== 'up') {
        player.play('up', true);
      }
    } else if (cursors.down.isDown || Keys.S.isDown || player.getData('isMovingDown')) {
      player.setVelocityY(200);
      if (player.anims.currentAnim.key !== 'down') {
        player.play('down', true);
      }
    } else {
      if (player.anims.currentAnim.key !== 'idle') {
        player.play('idle', true);
      }
    }
  
    playerPosition.x = player.x;
    playerPosition.y = player.y;
  
    console.log(`Player position: x=${playerPosition.x}, y=${playerPosition.y}`);
  };
  
  
  export const renderPlayerControls = () => {
    return (
      <div className='row'>
        <div id="controls">
          <div id='column'>
  
          <div id='button-row'>
            <div id="up-button" style={{ width: '40px', height: '40px', backgroundImage: 'url(/assets/buttons/up.png)', backgroundSize: 'cover' }}></div>
          </div>
          <div id='rowbutton' >
  
          <div id='button-row'>
            <div id="left-button" style={{ width: '40px', height: '40px', backgroundImage: 'url(/assets/buttons/left.png)', backgroundSize: 'cover' }}></div>
            <div id="down-button" style={{ width: '40px', height: '40px', backgroundImage: 'url(/assets/buttons/down.png)', backgroundSize: 'cover' }}></div>
            <div id="right-button" style={{ width: '40px', height: '40px', backgroundImage: 'url(/assets/buttons/right.png)', backgroundSize: 'cover' }}></div>
          </div>
          </div>
          </div>
          <div id='column'>
  
          <div id='button-rowab'>
            <div id="button-a" style={{ width: '40px', height: '40px', backgroundImage: 'url(/assets/buttons/A.png)', backgroundSize: 'cover' }}></div>
            <div id="button-b" style={{ width: '40px', height: '40px', backgroundImage: 'url(/assets/buttons/B.png)', backgroundSize: 'cover' }}></div>
          </div>
          </div>
        </div>
      </div>
    );
  };