import React, { useEffect } from 'react';
import Phaser from 'phaser';
import { collision, doors } from '../asset/tilemap/collisions';
import { preloadPlayer, createPlayer, updatePlayer, setupPlayerControls, renderPlayerControls } from './player';
import { createDialogBox } from './dialogBox';
import './gamemap.css';
import NewScene from './newScene';  


let hasDialogBoxShown = false;
const GameMap = () => {
  useEffect(() => {
    const config = {
      type: Phaser.AUTO,
      width: '100%',
      height: '100%',
      parent: 'game-container',
      physics: {
        default: 'arcade',
        arcade: {
          gravity: { y: 0 },
          debug: false,
        },
      },
      scale: {
        mode: Phaser.Scale.FIT,
      },
      pixelArt: true,
      scene: {
        preload: preload,
        create: create,
        update: update,
        cleanup: cleanup  
      }
    };

    const game = new Phaser.Game(config);
    game.scene.add('NewScene', NewScene);  
    game.scene.add('GameMap', { key: 'GameMap', create: create, preload: preload, update: update, cleanup: cleanup });  

    let cursors;
    let player;
    const tileSize = 48;
    const scaledTileWidth = tileSize * 0.5;
    const scaledTileHeight = tileSize * 0.3;
    const offsetX = -400;
    const offsetY = -500;
    const collisionlayer = [];
    const doorlayer = [];
    let playerPosition = { x: 700, y: 550 };
    let lastDoorPosition = null;

    // Define canPlayerMove at the class level
    let canPlayerMove = false;

    const messages = [
      'Hello everyone, My name is Heman Das.',
      'I have a background in software engineering, Graphic Designing and have been working in this industry for over 2-3 years.',
      'This may seem like a RPG game but this website is developed for the sole purpose of my Portfolio ( wanted to create a unique portfolio).',
      'Sorry to disappoint you.',
      'This is my first project  on React as I just started to learn it (Date:July 1,2024)',
      "it's not much but i have created some unique things navigate through the map to discover it ",
      "Also special thanks to:",
      "Pablo Montrego: (https:// javascript .plainenglish.io/ i-made- a-top -down- game-version -of- my-blog -with -phaser-and -react-faf5c28cf768)",
      "Chris Courses: (https:// www.youtube. com/watch?v= yP5DKzriqXA)",
      "Sorry about all the message, just wanted to give small introduction. From next time just press 'ESC' or 'B button if on mobile' or the easiest one 'click on close icon'"
      
    ];

    function preload() {
      console.log("Preloading assets...");

      this.load.on('complete', () => {
        console.log('All assets loaded');
      });

      this.load.on('loaderror', (file) => {
        console.error(`Error loading file: ${file.key}`);
      });

      this.load.image('map', '/assets/tiles/homepage(portfolio).png');
      this.load.image('foreground', '/assets/tiles/Foreground.png');
      this.load.image('dialogBox', '/assets/tiles/dialogbox.png'); 
      this.load.image('closeButton', '/assets/buttons/closebutton.png'); 
      this.load.image('nextButton', '/assets/buttons/nextbutton.png'); 

      preloadPlayer(this);
    }

    function create(data) {
      console.log("Creating scene...");

      if (this.textures.exists('map')) {
        this.add.image(offsetX, offsetY, 'map').setOrigin(0, 0);
      } else {
        console.error('Map image not found in cache');
      }

      playerPosition = data?.playerPosition || playerPosition; // Update player position if data is passed
      player = createPlayer(this, playerPosition);
      setupPlayerControls(this, player);

      cursors = this.input.keyboard.createCursorKeys();
      

      

      for (let i = 0; i < collision.length; i += 80) {
        collisionlayer.push(collision.slice(i, 80 + i));
      }

      for (let i = 0; i < doors.length; i += 80) {
        doorlayer.push(doors.slice(i, 80 + i));
      }

      this.collisionTiles = this.physics.add.staticGroup();
      this.doorTiles = this.physics.add.staticGroup();
      this.graphics = this.add.graphics({ fillStyle: { color: 0xff0000, alpha: 0.3 } });

      collisionlayer.forEach((row, rowIndex) => {
        row.forEach((tile, colIndex) => {
          if (tile === 42) {
            const tileX = colIndex * tileSize + offsetX + 25;
            const tileY = rowIndex * tileSize + offsetY + 20;
            const collisionTile = this.collisionTiles.create(tileX, tileY, null).setOrigin(0, 0).setDisplaySize(scaledTileWidth, scaledTileHeight).setVisible(false);
            collisionTile.body.setSize(scaledTileWidth, scaledTileHeight).setOffset(0, 0);
            // this.graphics.fillRect(tileX, tileY, scaledTileWidth, scaledTileHeight);
          }
        });
      });

      doorlayer.forEach((row, rowIndex) => {
        row.forEach((tile, colIndex) => {
          if (tile === 1849) { // Assuming 1849 is the door tile value
            const tileX = colIndex * tileSize + offsetX + 25;
            const tileY = rowIndex * tileSize + offsetY + 15;
            const doorTile = this.doorTiles.create(tileX, tileY, null).setOrigin(0, 0).setDisplaySize(scaledTileWidth, scaledTileHeight).setVisible(false);
            doorTile.body.setSize(scaledTileWidth, scaledTileHeight).setOffset(0, 0);
            // this.graphics.fillRect(tileX, tileY, scaledTileWidth, scaledTileHeight);
          }
        });
      });

      this.physics.add.collider(player, this.collisionTiles, () => {
        console.log('Colliding with collision tile');
      });

      this.physics.add.overlap(player, this.doorTiles, (player, doorTile) => {
        console.log('Colliding with door tile');
        lastDoorPosition = { x: doorTile.x, y: doorTile.y };
        this.scene.stop('GameMap');  // Pause GameMap
        cleanup.call(this);  // Clean up resources
        this.scene.start('NewScene', { playerPosition: lastDoorPosition });
      });

      this.cameras.main.startFollow(player, true, 0.05, 0.05);
      this.cameras.main.setBounds(0 + offsetX, 0 + offsetY, 3400 - offsetX, 1400 - offsetY);

      this.foreground = this.add.image(offsetX, offsetY, 'foreground').setOrigin(0, 0);

      // Create the dialog box with messages

      if (!hasDialogBoxShown) {
        hasDialogBoxShown = true;
        createDialogBox(this, player, messages, () => {
          canPlayerMove = true; // Allow player to move after dialog box is closed
        });
      }
      
    }

    function update() {
      if (player && canPlayerMove) {
        updatePlayer(this, player, cursors, playerPosition);
      }
    }

    function cleanup() {
      // Clean up resources to free memory
      if (this.collisionTiles) {
        this.collisionTiles.clear(true, true);
      }
      if (this.doorTiles) {
        this.doorTiles.clear(true, true);
      }
      if (this.graphics) {
        this.graphics.destroy();
      }
      if (this.foreground) {
        this.foreground.destroy();
      }
      if (player) {
        player.destroy();
        player = null; // Ensure player is set to null after destruction
      }
      console.log("GameMap resources cleaned up");
    }

    return () => {
      game.destroy(true);
    };
  }, []);

  return (
    <div id="game-container" style={{ width: '100%', height: '100vh', position: 'relative' }}>
      {renderPlayerControls()}
    </div>
  );
};

export default GameMap;
