import React from 'react';
import './aboutme.css';
import CircularProgress from '../asset/elements/circularprogress';

const AboutMe = () => (
  <div className="about-section">
    <div className="first-page">
      <h2>About Me</h2>
      <div className='top'>
        <div className='profilepic'>
          <img
            src="profile.png"
            alt="profile"
            className="profile-pic"
          />
        </div>
        <div className='profile-info'>
          <h3>Personal Info</h3>
          <p><b>Name:</b> Heman Das</p>
          <p><b>DOB:</b> 2001/05/15</p>
          <p><b>Sex:</b> Male, female, sometimes animal XD</p>
          <p><b>Country:</b> Nepal</p>
        </div>
      </div>
      <div className='bottom'>
        <h3 className='skills'>Skills:</h3>
        <p><b>React:</b> <CircularProgress percent={5} /></p>      
        <p><b>Html:</b> <CircularProgress percent={5} /></p>      
        <p><b>CSS:</b> <CircularProgress percent={5} /></p>      
        <p><b>Java:</b> <CircularProgress percent={5} /></p>      
        <p><b>flutter:</b> <CircularProgress percent={5} /></p>      
        <p><b>wordpress:</b> <CircularProgress percent={30} /></p>      
        <p><b>Graphic Design:</b> <CircularProgress percent={50} /></p>      
      </div>
    </div>
    <div className="second-page">
    <div className='top-2'>
      <h2>Profile:</h2>
      <p>Hey there! I'm a tech enthusiast just starting out with React, HTML, CSS, and Java. I'm great at WordPress and have a strong knack for Graphic Design.<br/> When I'm not coding, you'll find me playing video games, reading light novels, watching movies, dancing, going on bike rides, enjoying anime, and drawing. Always up for new adventures and learning something new! </p>
      
    </div>
    <div className='top-2'>
      <h2>Education:</h2>
      <p><b>S.E.E:</b> Galaxy Public School(2017)</p>
      <p><b>XI/XII:</b> Olympia World College(2019)</p>
      <p><b>Bachelor:</b> Lalitpur Engineering College(Dropped Out)</p>
    </div>
    <div className='bottom-2'>
      <h2>Hobbies:</h2>
      <ul>
        <li>Video Games</li>
        <li>Reading Light Novels</li>
        <li>Watching Movies</li>
        <li>Dancing</li>
        <li>Bike Rides</li>
        <li>MonKE</li>
        <li>Watching Animes</li>
        <li>Drawing </li>
        <li>& more </li>
      </ul>
    </div>
    
    </div>
  </div>
);

export default AboutMe;
