import React, { useEffect, useRef, useState } from 'react';
import { useBlogData, BlogSection } from '../pages/blogs';
import AboutMe from '../pages/aboutme';
import MyWorks from '../pages/myworks';
import Specialthanks from '../pages/specialthanks';
import './popup.css';

const BookPopup = ({ show, onClose }) => {
    const row2Ref = useRef(null);
    const column1Ref = useRef(null);
    // eslint-disable-next-line
    const column2Ref = useRef(null);
    const {
        categories,
        subcategories,
        blogs,
        selectedBlog,
        showSubcategories,
        showBlogContent,
        selectedIndex,
        selectedBlogIndex,
        setSelectedIndex,
        setSelectedCategory,
        setShowSubcategories,
        setShowBlogContent,
        setBlogs,
        setSelectedBlog,
        setSelectedBlogIndex,
    } = useBlogData(show);

    const [currentSection, setCurrentSection] = useState('mainMenu');

    useEffect(() => {
        const handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowUp':
                case 'w':
                    if (currentSection === 'blogs' && showBlogContent) {
                        setSelectedBlogIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                    } else {
                        setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex));
                    }
                    break;
                case 'ArrowDown':
                case 's':
                    if (currentSection === 'blogs' && showBlogContent) {
                        setSelectedBlogIndex((prevIndex) => (blogs.length - 1 > prevIndex ? prevIndex + 1 : prevIndex));
                    } else {
                        setSelectedIndex((prevIndex) =>
                            (currentSection === 'blogs' ? (showSubcategories ? subcategories : categories) : mainMenu).length - 1 > prevIndex
                                ? prevIndex + 1
                                : prevIndex
                        );
                    }
                    break;
                    case 'Enter':
                      if (currentSection === 'mainMenu') {
                          handleMenuSelect(mainMenu[selectedIndex]);
                      } else if (currentSection === 'blogs') {
                          if (showSubcategories && !showBlogContent) {
                              const currentSubcategory = subcategories[selectedIndex];
                              if (currentSubcategory) {
                                  setBlogs(currentSubcategory.blogs || []);
                                  setShowBlogContent(false);
                              }
                          } else if (!showBlogContent) {
                              const currentCategory = categories[selectedIndex];
                              if (currentCategory) {
                                  handleCategoryClick(currentCategory.id);
                              }
                          } else {
                              const currentBlog = blogs[selectedBlogIndex];
                              if (currentBlog) {
                                  setSelectedBlog(currentBlog);
                                  setShowBlogContent(true);
                              }
                          }
                      }
                      break;
                
                case 'Backspace':
                    if (currentSection === 'blogs' && showSubcategories) {
                        handleBackClick();
                    } else if (currentSection !== 'mainMenu') {
                        setCurrentSection('mainMenu');
                    }
                    break;
                
                case 'Escape':
                    onClose();
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        const touchButtonA = document.getElementById('button-a');
        const touchButtonB = document.getElementById('button-b');
        const touchButtonup = document.getElementById('up-button');
        const touchButtondown = document.getElementById('down-button');

        const triggerEscape = () => {
            const escEvent = new KeyboardEvent('keydown', { key: 'Backspace' });
            window.dispatchEvent(escEvent);
        };
        const triggerEnter = () => {
            const enterEvent = new KeyboardEvent('keydown', { key: 'Enter' });
            window.dispatchEvent(enterEvent);
        };
        const navigateup = () => {
            const upEvent = new KeyboardEvent('keydown', { key: 'ArrowUp' });
            window.dispatchEvent(upEvent);
        };
        const navigatedown = () => {
            const downEvent = new KeyboardEvent('keydown', { key: 'ArrowDown' });
            window.dispatchEvent(downEvent);
        };

        touchButtonA?.addEventListener('touchstart', triggerEnter);
        touchButtonB?.addEventListener('touchstart', triggerEscape);
        touchButtonup?.addEventListener('touchstart', navigateup);
        touchButtondown?.addEventListener('touchstart', navigatedown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            touchButtonA?.removeEventListener('touchstart', triggerEnter);
            touchButtonB?.removeEventListener('touchstart', triggerEscape);
            touchButtonup?.removeEventListener('touchstart', navigateup);
            touchButtondown?.removeEventListener('touchstart', navigatedown);
        };
        // eslint-disable-next-line
    }, [selectedIndex, showSubcategories, showBlogContent, categories, subcategories, currentSection, selectedBlogIndex, blogs]);

    const mainMenu = ['About Me', 'My Works', 'Blogs','Special Thanks'];

    const handleMenuSelect = (menuOption) => {
        switch (menuOption) {
            case 'About Me':
                setCurrentSection('aboutMe');
                break;
            case 'My Works':
                setCurrentSection('myWorks');
                break;
            case 'Special Thanks':
                setCurrentSection('Special Thanks');
                break;
            case 'Blogs':
                setCurrentSection('blogs');
                break;
            default:
                break;
        }
        setSelectedIndex(0);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        setShowSubcategories(true);
        setSelectedIndex(0);
    };

    const handleBackClick = () => {
        setShowSubcategories(false);
        setShowBlogContent(false);
        setSelectedCategory(null);
        setSelectedBlog(null);
        setSelectedIndex(0);
        setSelectedBlogIndex(0);
        setBlogs([]);
    };

    if (!show) {
        return null;
    }

    return (
        <div className="popup-overlay">
            <div className="popup-container">
                <div className="popup-content">
                    <div className="row1">
                        {currentSection !== 'mainMenu' && (
                            <img
                                src="/assets/buttons/backbutton.png"
                                alt="back-button"
                                onClick={() => {if (currentSection === 'blogs' && showSubcategories) {
                                  handleBackClick();} else{setCurrentSection('mainMenu')}}}
                                className="back-button"
                            />
                        )}
                        <img
                            src="/assets/buttons/closebutton.png"
                            alt="close-button"
                            onClick={onClose}
                            className="close-button"
                        />
                    </div>
                    <div className="row2" ref={row2Ref}>
                        {currentSection === 'mainMenu' && (
                            <div className="column1" ref={column1Ref}>
                                {mainMenu.map((menuOption, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleMenuSelect(menuOption)}
                                        className={`clickable ${index === selectedIndex ? 'selected' : ''}`}
                                    >
                                        {menuOption}
                                    </div>
                                ))}
                            </div>
                        )}
                        {currentSection === 'aboutMe' && <AboutMe />}
                        {currentSection === 'myWorks' && <MyWorks />}
                        {currentSection === 'Special Thanks' && <Specialthanks />}
                        {currentSection === 'blogs' && (
                            <BlogSection
                                categories={categories}
                                subcategories={subcategories}
                                blogs={blogs}
                                selectedBlog={selectedBlog}
                                showSubcategories={showSubcategories}
                                showBlogContent={showBlogContent}
                                selectedIndex={selectedIndex}
                                selectedBlogIndex={selectedBlogIndex}
                                handleCategoryClick={handleCategoryClick}
                                setBlogs={setBlogs}
                                setSelectedIndex={setSelectedIndex}
                                setShowBlogContent={setShowBlogContent}
                                setSelectedBlog={setSelectedBlog}
                                setSelectedBlogIndex={setSelectedBlogIndex}
                            />
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BookPopup;
