import React, { useState } from 'react';
import './specialthanks.css';




const Specialthanks = () => {
  const [websiteLinks] = useState([
    { text: 'Pablo Montrego', url: 'https://javascript.plainenglish.io/i-made-a-top-down-game-version-of-my-blog-with-phaser-and-react-faf5c28cf768' },
    { text: 'Chris Courses', url: 'https://www.youtube.com/watch?v=yP5DKzriqXA' },
    { text: 'photonstorm, for creating Phaser.io.', url: 'https://github.com/photonstorm' },
    { text: 'ArMM1998, for the characters sprites and tilesets.', url: 'https://itch.io/profile/armm1998' },
    { text: 'Min, for the open book sprite.', url: 'https://opengameart.org/users/min' },
    
  ]);

  const renderLinks = (links) => {
    return (
      <ul>
        {links.map((link, index) => (
          <li key={index}>
            <a href={link.url} target="_blank" rel="noopener noreferrer">
              {link.text}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="specialthanks">
      <div className='firstpage'>
        <h2>Special Thanks to:</h2>
        <div className='links'>
          
          {renderLinks(websiteLinks)}
        </div>
      </div>
      <div className='secondpage'>
      </div>
    </div>
  );
};

export default Specialthanks;
